export const formatDate = (dateString) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };

  // S'assurer que le fuseau horaire est bien pris en compte
  const date = new Date(dateString);

  // Si la date n'est pas valide, renvoyer une chaîne vide (gestion d'erreurs)
  if (isNaN(date.getTime())) {
    return '';
  }

  const formattedDate = date.toLocaleDateString('fr-FR', options);
  return formattedDate;
};


export default function formatNumber(number) {
  if (isNaN(number)) return number; // Si ce n'est pas un nombre, retournez-le tel quel.

  // Convertir le nombre en entier (en retirant les décimales)
  const intPart = Math.round(number);
  // const intPart = Math.round(number);

  // Ajouter des points tous les trois chiffres à la partie entière
  return String(intPart).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const formatDateWithTime = (dateString) => {
  // Convertir la chaîne en objet Date
  const date = new Date(dateString);

  // Vérifier si la date est valide
  if (isNaN(date.getTime())) {
    return '';
  }

  // Options pour le formatage en français
  const options = {
    year: 'numeric',   // Année complète (ex : 2024)
    month: 'numeric',     // Mois complet (ex : novembre)
    day: 'numeric',    // Jour du mois (ex : 27)
    hour: '2-digit',   // Heure (ex : 20)
    minute: '2-digit', // Minute (ex : 32)
  };

  // Formater la date et l'heure en français
  const formattedDate = date.toLocaleDateString('fr-FR', options);

  // Ajouter "à" entre la date et l'heure
  return formattedDate;
}

export const convertNumberToWordsInFCFA = (amount) => {
  const units = ["", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf", "dix", "onze", "douze", "treize", "quatorze", "quinze", "seize", "dix-sept", "dix-huit", "dix-neuf"];
  const tens = ["", "", "vingt", "trente", "quarante", "cinquante", "soixante", "soixante-dix", "quatre-vingts", "quatre-vingt-dix"];
  const thousands = ["", "mille", "million", "milliard"];
  const hundreds = ["", "cent", "deux cents", "trois cents", "quatre cents", "cinq cents", "six cents", "sept cents", "huit cents", "neuf cents"];

  // Fonction pour convertir les centaines, dizaines et unités
  function convertToWords(number) {
    let words = '';

    if (number >= 100) {
      let hundredsPart = Math.floor(number / 100);
      words += hundreds[hundredsPart] + " ";
      number %= 100;
    }

    if (number >= 20) {
      let tensPart = Math.floor(number / 10);
      words += tens[tensPart] + " ";
      number %= 10;
    }

    if (number > 0) {
      words += units[number] + " ";
    }

    return words.trim();
  }

  // Convertir la partie entière (avant la virgule)
  function convertIntegerPart(integerPart) {
    let words = "";
    let thousandIndex = 0;

    while (integerPart > 0) {
      let currentPart = integerPart % 1000;
      if (currentPart > 0) {
        words = convertToWords(currentPart) + (thousands[thousandIndex] ? " " + thousands[thousandIndex] : "") + " " + words;
      }
      integerPart = Math.floor(integerPart / 1000);
      thousandIndex++;
    }

    return words.trim();
  }

  // Convertir la partie décimale (après la virgule)
  function convertDecimalPart(decimalPart) {
    return decimalPart > 0 ? " et " + convertToWords(decimalPart) : "";
  }

  if (amount === 0) {
    return "zéro FCFA";
  }

  let integerPart = Math.floor(amount);
  let decimalPart = Math.round((amount - integerPart) * 100); // Centimes

  let integerWords = convertIntegerPart(integerPart);
  let decimalWords = convertDecimalPart(decimalPart);

  return integerWords + (decimalWords ? decimalWords : "") + " FCFA";
}

export const generateQuantityDescription = (quantity, product) => {
  let totalCartons = 0;
  let totalPieces = 0;
  let remainingM2 = 0;
  let description = "";

  if (product.category_id === 17) { // Catégorie des produits en m²
    const m2PerCarton = parseFloat(product.nb_m2_par_carton || 0);
    const m2PerPiece = parseFloat(product.nb_m2_par_piece || 0);

    if (m2PerCarton > 0) {
      totalCartons = Math.floor(quantity / m2PerCarton);
      remainingM2 = quantity % m2PerCarton;

      if (m2PerPiece > 0) {
        totalPieces = Math.floor(remainingM2 / m2PerPiece);
        // Ajustement pour éviter que le m² restant dépasse une pièce
        if (remainingM2 >= m2PerPiece) {
          totalPieces += 1;
          remainingM2 = 0;
        } else {
          remainingM2 = remainingM2 % m2PerPiece;
        }
      }

      description = `${totalCartons} carton(s)`;
      if (totalPieces > 0) {
        description += `, ${totalPieces} pièce(s)`;
      }
      if (remainingM2 > 0) {
        description += ` et ${remainingM2.toFixed(2)} m²`;
      }
    } else {
      description = "Données insuffisantes pour calculer.";
    }
  } else { // Autres catégories (par pièces)
    const piecesPerCarton = parseInt(product.nb_pieces_par_carton || 0);

    if (piecesPerCarton > 0) {
      totalCartons = Math.floor(quantity / piecesPerCarton);
      totalPieces = quantity % piecesPerCarton;

      description = `${totalCartons} carton(s)`;
      if (totalPieces > 0) {
        description += ` et ${totalPieces} pièce(s)`;
      }
    } else {
      description = `${quantity} pièce(s)`;
    }
  }

  return description;
};

// Fonction pour formater la date
const formatDateTime = (dateString) => {
  if (!dateString) return ''; // Si la date est vide ou invalide

  const date = new Date(dateString);
  const options = {
    weekday: 'long', // Jour de la semaine complet (ex. "Monday")
    year: 'numeric',
    month: 'long', // Mois complet (ex. "December")
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true, // Pour afficher l'heure en format AM/PM
  };

  return date.toLocaleString('fr-FR', options); // Formater selon la locale française
};