import React from "react";
import formatNumber, { convertNumberToWordsInFCFA } from "../utils/functions";
import { APP_NAME } from "../utils";

export default function FactureProFormatComponent({ invoiceData, customer, isCopy = true }) {

  return (
    <div className={isCopy ? 'invoice-copy second-copy' : 'invoice-copy'}>
      <div className="flex flex-row justify-between items-start mt-10">
        <div className="">
          <img src="/assets/img/logo.png" height={100} width={100} alt={APP_NAME} />

          <p className="text-sm font-medium tracking-widest mt-3">
            ASEBAT SARL
          </p>
          <p className="text-sm font-medium">C/1775 C, QUARTIER GBEDJEKOME - OUEDO</p>
          <p className="text-sm font-medium">BP 952  ABOMEY - CALAVI,BENIN</p>
          <p className="text-sm font-medium">IFU 3202434985932 - RCCMRB/ABC/24 B 7993</p>
          <p className="text-sm font-medium">+229 01 61 89 89 00</p>
        </div>
      </div>

      <p className="text-sm font-medium mt-2">
        <span className="font-bold">Pro Format</span>
      </p>
      <div className="overflow-x-auto">
        <table className="border-collapse table-auto w-full text-sm mt-2 whitespace-pre">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 pe-7 border font-semibold">Code client</th>
              <th className="p-2 border font-semibold text-start">Client</th>
              <th className="p-2 border font-semibold">Téléphone</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            <tr>
              <td className="p-2 font-medium border">{customer?.client_code}</td>
              <td className="p-2 font-medium border">{customer?.name}</td>
              <td className="p-2 font-medium border">{customer?.numero}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="overflow-x-auto">
        <table className="border-collapse table-auto w-full text-sm whitespace-pre">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 border text-sm font-semibold">
                Produit
              </th>
              <th className="p-2 border text-sm font-semibold">
                Prix Unitaire
              </th>
              <th className="p-2 pe-7 border text-sm font-semibold">
                Quantité
              </th>
              <th className="p-2 border text-sm font-semibold">
                Prix Total
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {invoiceData?.produits?.length > 0 ? (
              invoiceData.produits.map((item, index) => (
                <tr key={index}>
                  <td className="p-2 text-sm font-medium border">
                    {item.name}
                  </td>
                  <td className="p-2 text-sm font-medium border">
                    {formatNumber(item.p2)} FCFA
                  </td>
                  <td className="p-2 text-sm font-medium border">
                    {item.quantite} {item.unite}(s)
                  </td>
                  <td className="p-2 text-sm font-medium border">
                    {formatNumber(item.p2 * item.quantite)} FCFA
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="p-2 text-center text-sm font-medium">
                  Aucun produit dans le panier.
                </td>
              </tr>
            )}
            <tr className="bg-gray-100">
              <td colSpan={4} className="p-2 text-sm font-semibold border">
                Commentaires
              </td>
            </tr>
            <tr>
              <td
                colSpan={3}
                rowSpan={4}
                className="p-2 text-sm font-medium border border-e-0"
              >
                Les marchandises vendues ne sont ni reprises ni échangées. <br />La disponibilité de votre achat n'est plus garantie 14 jours <br />après la date de facturation.
              </td>
              <td className="p-2 text-sm font-semibold border text-end bg-gray-100">
                <b>Montant HT :</b> {formatNumber(invoiceData.montant_ht)} FCFA
              </td>
            </tr>
            <tr>
              <td className="p-2 text-sm font-semibold border text-end bg-gray-100">
                {/* Montant de la TVA en espèces */}
                <b>T.V.A (18%) :</b> {formatNumber(invoiceData.tva)} FCFA
              </td>
            </tr>
            <tr>
              <td className="p-2 text-sm font-semibold border text-end bg-gray-100">
                {/* Montant de la TVA en espèces */}
                <b>AIB (01%) :</b> 0.00 FCFA
              </td>
            </tr>
            <tr>
              <td className="p-2 text-sm font-semibold border text-end bg-gray-100">
                {/* Remise (si applicable) */}
                <b>Remise :</b> {formatNumber(invoiceData.remise)} FCFA
              </td>
            </tr>
            <tr>
              <td colSpan={5} className="p-2 text-sm font-semibold border text-end">
                {/* Montant TTC (NET) */}
                <b>NET :</b> {formatNumber(invoiceData.montant_net)} FCFA
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flex flex-wrap items-center justify-between gap-6 mt-3">
        <div>
          <p className="text-sm font-medium mb-2 capitalize">
            <span className="font-bold">Montant en lettre</span> : {convertNumberToWordsInFCFA(invoiceData?.montant_net)}
          </p>
        </div>
      </div>
    </div>
  );
}