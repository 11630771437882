import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { utils, writeFile } from 'xlsx';
import { APP_API_URL, APP_NAME } from '../../utils';

import axios from 'axios';
import { formatDate, formatDateWithTime } from '../../utils/functions';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import OrderLayout from '../../layout/order-layout';
import Loader from '../../components/loader';

export default function History() {
  const user = useSelector((state) => state.auth.user);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'statut_commande', direction: 'ascending' });

  const [isOpen, setIsOpen] = useState(false);

  // États pour les filtres
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [loading, setLoading] = useState(false);

  // États pour les données
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/commandes/user/${user.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setData(response.data.commandes);
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes :', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const applyFilters = () => {
    const result = data.filter(item => {
      const matchesStatus = selectedStatus ? item.commande.statut_commande === selectedStatus : true;
      const matchesDate = selectedDate ? new Date(item.commande.created_at).toDateString() === new Date(selectedDate).toDateString() : true;

      return matchesStatus && matchesDate;
    });

    setFilteredData(result);
    setCurrentPage(1);
  };

  useEffect(() => {
    const result = data.filter(item =>
      item.commande?.statut_commande.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(result);
    setCurrentPage(1);
  }, [searchTerm, data]);

  const sortedItems = [...filteredData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedItems.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };

  const handleRefresh = async () => {
    await fetchData();
    setCurrentPage(1);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text('Commandes', 14, 16);
    const tableColumn = ["Commande", "Statut", "Client", "Date de création", "Total"];
    const tableRows = [];

    currentItems.forEach(item => {
      const data = [
        item.reference_commande,
        item.statut_commande,
        item.client?.name || 'Inconnu',
        formatDate(item.date_creation),
        `${item.total} €`
      ];
      tableRows.push(data);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save(`Commandes ${APP_NAME}.pdf`);
  };

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(currentItems);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Commandes");
    writeFile(workbook, `Commandes ${APP_NAME}.xlsx`);
  };

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <OrderLayout>
      <div className="mx-auto w-full min-h-full max-w-6xl bg-white p-4 sm:p-6 lg:p-8">
        <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center mb-16'>
          <div className="pagetitle">
            <h1>Commandes</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">Historique des commandes</li>
              </ol>
            </nav>
          </div>

          <div className='flex flex-col lg:flex-row gap-x-2 gap-y-4'>
            <div className='flex flex-row gap-x-2'>
              <button title='Exporter en PDF' onClick={exportToPDF} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                <i className="bi bi-filetype-pdf"></i>
              </button>
              <button title='Exporter en Exel' onClick={exportToExcel} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                <i className="bi bi-filetype-xls"></i>
              </button>
              <button title='Imprimer' onClick={handlePrint} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                <i className="bi bi-printer"></i>
              </button>
              <button title='Rafraichir' onClick={handleRefresh} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                <i className="bi bi-arrow-clockwise"></i>
              </button>
            </div>
          </div>
        </div>

        <section className='section'>
          <div className="card mb-4">
            <div className="card-header pb-4">
              <div className="row justify-content-between">
                <div className="col-md-6">
                  <div>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Rechercher..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end gap-x-2 col-lg-2 col-md-6 mt-3 mt-md-0">
                  <button
                    type="button"
                    title='Options de filtrage'
                    onClick={toggleAccordion}
                    className={`inline-flex items-center border rounded-md px-3 py-2 text-sm font-semibold hover:bg-gray-300 transition-all duration-300 ${isOpen ? 'bg-red-500 text-white' : 'bg-gray-50 text-gray-950'}`}
                  >
                    <i className={`bi ${isOpen ? 'bi-x-lg' : 'bi-funnel-fill'}`}></i>
                  </button>
                </div>
              </div>

              <div className="accordion" id="accordionExample">
                <div className="accordaion-item">
                  <div className={`transition-max-height duration-300 ease-in-out overflow-hidden ${isOpen ? 'md:max-h-20' : 'max-h-0'}`}>
                    <div className="accordion-body px-0 border-t my-4">
                      <form className="row g-3">
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-3">
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Choisissez une date"
                                value={selectedDate}
                                onChange={e => setSelectedDate(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className='flex justify-end'>
                            <button type="button" onClick={applyFilters} className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 transition-all duration-300">
                              <i className="bi bi-search me-1" /> Recherche
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body p-0">
              <div className="table-responsive table-card">
                <table className="table text-nowrap mb-0 table-centered table-hover">
                  <thead className="table-light">
                    <tr>
                      <th className="ps-3" onClick={() => requestSort('commande', 'designation')}>
                        <button className='inline-flex items-center justify-between w-full'>
                          Référence
                          <i className="bi bi-arrow-down-up text-gray-400"></i>
                        </button>
                      </th>
                      <th className="" onClick={() => requestSort('commande', 'customer', 'name')}>
                        <button className='inline-flex items-center justify-between w-full'>
                          Client
                          <i className="bi bi-arrow-down-up text-gray-400"></i>
                        </button>
                      </th>
                      <th className="" onClick={() => requestSort('commande', 'customer', 'created_at')}>
                        <button className='inline-flex items-center justify-between w-full'>
                          Date de creation
                          <i className="bi bi-arrow-down-up text-gray-400"></i>
                        </button>
                      </th>
                      <th>
                        <button>Status</button>
                      </th>
                      <th>
                        <button>Action</button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((item, index) => (
                        <tr key={'Commande-' + index} className='align-middle'>
                          <td className="ps-3 font-semibold">
                            {item.commande.designation}
                          </td>
                          <td>
                            {item?.commande.customer?.name}
                          </td>
                          <td>
                            {formatDateWithTime(item?.commande.created_at)}
                          </td>
                          <td>
                            {item.commande.statut_commande === "en cours" && (<span className="badge bg-warning">En cours</span>)}
                            {item.commande.statut_commande === "approuvé" && (<span className="badge bg-info">Approuvé</span>)}
                            {item.commande.statut_commande === "livré" && (<span className="badge bg-success">Livré</span>)}
                          </td>
                          <td>
                            <Link to={`/commande/${item.commande.designation}`}
                              className="btn btn-ghost btn-icon bg-lightgreen hover:bg-lime-800 text-white btn-xs rounded-xl texttooltip border"
                              title="Valider"
                            >
                              <div className='flex flex-row'>
                                <i className="bi bi-eye mr-2"></i>
                                <div id="editOne" className="d-nonea">
                                  <span>Voir</span>
                                </div>
                              </div>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="datatable-empty" colSpan="10">Aucun item correspondant aux critères de recherche.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card-footer d-md-flex justify-content-between align-items-center">
              <span>
                {currentItems.length > 0
                  ? `Affichage de ${Math.max(indexOfFirstItem + 1, 0)} à ${Math.min(indexOfLastItem, currentItems.length)} sur ${sortedItems.length} entrée${sortedItems.length > 1 ? 's' : ''}`
                  : `Aucune entrée à afficher`}
                {currentItems.length === 0 && data.length > 0 && ` (filtré à partir de ${data.length} entrée${data.length > 1 ? 's' : ''} totales)`}
              </span>

              {currentItems.length > 0 && (
                <nav className="mt-2 mt-md-0">
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <a className="page-link" href="#!" onClick={handlePrevPage} disabled={currentPage === 1}>
                        Précédent
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#!" onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Suivant
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </section>
      </div>
    </OrderLayout>
  );
};
