import React, { useState, useEffect } from 'react';
// import data from './data/index.json';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { utils, writeFile } from 'xlsx';
import MainLayout from '../../layout/main-layout';
import { APP_API_URL } from '../../utils';
import { Link } from 'react-router-dom';
import { useDataContext } from '../../context/DataContext';
import axios from 'axios';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import Loader from '../../components/loader';

const ProductTable = () => {
  const { products, categories } = useDataContext();

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // États pour les filtres
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [priceRange, setPriceRange] = useState([0, 100000]);

  // États pour les données
  const [productsData, setProductsData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    setProductsData(products);
    setFilteredProducts(products);
  }, [products]);

  useEffect(() => {
    const fetchBrands = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${APP_API_URL}/marques`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setBrandsData(response.data.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des marques :', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    GLightbox({
      selector: '.glightbox'
    });
  },);

  useEffect(() => {
    if (categories && categories.length > 0) {
      const mainCategories = categories.filter(category => category.type_categorie === null);
      const subCategories = categories.filter(category => category.type_categorie !== null);

      setCategoriesData(mainCategories);
      setSubCategoriesData(subCategories);
    }
  }, [categories]);

  const filteredSubCategories = selectedCategory
    ? subCategoriesData.filter(subCategory => subCategory.category_id === Number(selectedCategory))
    : subCategoriesData;

  const applyFilters = () => {
    const result = productsData.filter(product => {
      const matchesCategory = selectedCategory ? product.category_id === Number(selectedCategory) : true;
      const matchesSubCategory = selectedSubCategory ? product.sub_category_id === Number(selectedSubCategory) : true;
      const matchesBrand = selectedBrand ? product.marque_id === Number(selectedBrand) : true;
      const matchesPriceRange = product.price >= priceRange[0] && product.price <= priceRange[1];

      return matchesCategory && matchesSubCategory && matchesBrand && matchesPriceRange;
    });

    setFilteredProducts(result);
    setCurrentPage(1);
  };

  useEffect(() => {
    const result = productsData.filter(product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.reference.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredProducts(result);
    setCurrentPage(1);
  }, [searchTerm, productsData]);

  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(sortedProducts.length / productsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text('Liste des Produits', 14, 16);
    const tableColumn = ["Produit", "Référence", "Catégorie", "Sous-Catégorie", "Marque", "Créé par"];
    const tableRows = [];

    currentProducts.forEach(product => {
      const productData = [
        product.name,
        product.reference,
        product?.category?.nom,
        product?.sub_category?.nom,
        product?.marque?.nom,
        product?.user?.name,
      ];
      tableRows.push(productData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save(`Liste des Produits.pdf`);
  };

  const exportToExcel = () => {
    const tableData = currentProducts.map(product => ({
      Produit: product.name,
      Référence: product.reference,
      Catégorie: product?.category?.nom,
      SousCatégorie: product?.sub_category?.nom,
      Marque: product?.marque?.nom,
      CrééPar: product?.user?.name,
    }));

    const worksheet = utils.json_to_sheet(tableData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Produits");
    writeFile(workbook, `Liste des Produits.xlsx`);
  };

  // Fonction pour imprimer le tableau
  const handlePrint = () => {
    window.print();
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <MainLayout>
      <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center mb-16'>
        <div className="pagetitle">
          <h1>Liste des produits</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">Gérer vos produits</li>
            </ol>
          </nav>
        </div>

        <div className='flex flex-col lg:flex-row gap-x-2 gap-y-4'>
          <div className='flex flex-row gap-x-2'>
            <button onClick={exportToPDF} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-filetype-pdf"></i>
            </button>
            <button onClick={exportToExcel} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-filetype-xls"></i>
            </button>
            <button onClick={handlePrint} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-printer"></i>
            </button>
            <button type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>
          <div>
            <Link to="/add-product" className="inline-flex items-center rounded-md bg-green-600 px-2.5 py-2 text-sm font-semibold text-white hover:bg-green-500 transition-all duration-300">
              <i className="bi bi-plus me-1" /> Ajouter un nouveau produit
            </Link>
          </div>
        </div>
      </div>

      <section className='section'>
        <div className="card mb-4">
          <div className="card-header pb-4">
            <div className="row justify-content-between">
              <div className="col-md-6">
                <div>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Rechercher..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex items-center justify-end gap-x-2 col-lg-2 col-md-6 mt-3 mt-md-0">
                <button
                  type="button"
                  title='Options de filtrage'
                  onClick={toggleAccordion}
                  className={`inline-flex items-center border rounded-md px-3 py-2 text-sm font-semibold hover:bg-gray-300 transition-all duration-300 ${isOpen ? 'bg-red-500 text-white' : 'bg-gray-50 text-gray-950'}`}
                >
                  <i className={`bi ${isOpen ? 'bi-x-lg' : 'bi-funnel-fill'}`}></i>
                </button>
              </div>
            </div>

            <div className="accordion" id="accordionExample">
              <div className="accordaion-item">
                <div className={`transition-max-height duration-300 ease-in-out overflow-hidden ${isOpen ? 'md:max-h-20' : 'max-h-0'}`}>
                  <div className="accordion-body px-0 border-t my-4">
                    <form className="row g-3">
                      <div className="col-md-2">
                        <select className="form-select" onChange={(e) => setSelectedCategory(e.target.value)} defaultValue="">
                          <option value="">Toutes les Catégories</option>
                          {categoriesData.map((category) => (
                            <option key={category.id} value={category.id}>{category.nom}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-2">
                        <select className="form-select" onChange={(e) => setSelectedSubCategory(e.target.value)} defaultValue="">
                          <option value="">Toutes les Sous-Catégories</option>
                          {filteredSubCategories.map((subCategory) => (
                            <option key={subCategory.id} value={subCategory.name}>{subCategory.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-2">
                        <select className="form-select" onChange={(e) => setSelectedBrand(e.target.value)} defaultValue="">
                          <option value="">Toutes les Marques</option>
                          {brandsData.map((brand) => (
                            <option key={brand.id} value={brand.name}>{brand.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-2">
                        <select className="form-select" onChange={(e) => {
                          const selectedRange = e.target.value.split('-').map(Number);
                          setPriceRange(selectedRange);
                        }} defaultValue="">
                          <option value="0-10000">0 FCFA à 10 000 FCFA</option>
                          <option value="10000-50000">10 000 FCFA à 50 000 FCFA</option>
                          <option value="50000-100000">50 000 FCFA à 100 000 FCFA</option>
                          <option value="100000-1000000">100 000 FCFA à 1 000 000 FCFA</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <div className='flex justify-end'>
                          <button type="button" onClick={applyFilters} className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 transition-all duration-300">
                            <i className="bi bi-plus me-1" /> Recherche
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-0">
            <div className="table-responsive table-card">
              <table className="table text-nowrap mb-0 table-centered table-hover">
                <thead className="table-light">
                  <tr>
                    <th className="ps-3" onClick={() => requestSort('name')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Produit
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('reference')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Référence
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('category_id')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Catégorie
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    {/* <th onClick={() => requestSort('category_id')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Prix
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th> */}
                    <th onClick={() => requestSort('category_id')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Format
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('category_id')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Quantité
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('category_id')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Unité
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th>
                      <button>Action</button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentProducts.length > 0 ? (
                    currentProducts.map((product, index) => (
                      <tr key={index} className='align-middle'>
                        <td className="ps-3 font-semibold">
                          <div className='flex items-center gap-x-4'>
                            {/* {product.image && JSON.parse(product.image).length > 0 && (
                              <img
                                className='rounded-lg max-w-[60px] glightbox cursor-pointer'
                                src={APP_URL + '/storage/' + JSON.parse(product.image)[0]}
                                alt={product.name}
                              />
                            )} */}
                            {product.name}
                          </div>
                        </td>
                        <td>{product.reference}</td>
                        <td>{product?.category?.nom || 'N/A'}</td>
                        <td>{product?.format || 'N/A'}</td>
                        <td>{product?.variants?.reduce((price, variant) => parseFloat(price) + parseFloat(variant.quantity), 0)}</td>
                        <td>{product?.unit?.symbol || 'N/A'}</td>
                        <td>
                          <Link
                            to={`/product-details/${product.id}`}
                            className="btn btn-ghost btn-icon btn-xs rounded-xl texttooltip border"
                          >
                            <i className="bi bi-eye"></i>
                            <div id="editOne" className="d-none">
                              <span>Voir</span>
                            </div>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="datatable-empty" colSpan="10">Aucun produit correspondant aux critères de recherche.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer d-md-flex justify-content-between align-items-center">
            <span>
              {currentProducts.length > 0
                ? `Affichage de ${Math.max(indexOfFirstProduct + 1, 0)} à ${Math.min(indexOfLastProduct, currentProducts.length)} sur ${sortedProducts.length} entrée${sortedProducts.length > 1 ? 's' : ''}`
                : `Aucune entrée à afficher`}
              {currentProducts.length === 0 && categoriesData.length > 0 && ` (filtré à partir de ${categoriesData.length} entrée${categoriesData.length > 1 ? 's' : ''} totales)`}
            </span>

            {currentProducts.length > 0 && (
              <nav className="mt-2 mt-md-0">
                <ul className="pagination mb-0">
                  <li className="page-item">
                    <a className="page-link" href="#!" onClick={handlePrevPage} disabled={currentPage === 1}>
                      Précédent
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#!" onClick={handleNextPage} disabled={currentPage === totalPages}>
                      Suivant
                    </a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default ProductTable;
