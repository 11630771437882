import React, { useEffect, useState } from 'react';
import MainLayout from '../../layout/main-layout';
import UIDropdown from '../../components/ui/ui-dropdown';
import { useSelector } from 'react-redux';
import { APP_API_URL } from '../../utils';
import axios from 'axios';
import Add from '../variant/add';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import UIProductDropdown from '../../components/ui/ui-product-dropdown';

export default function AddSupply() {
  const user = useSelector((state) => state.auth.user);
  // const { products } = useDataContext([]);
  const initialFormData = {
    produit_id: '', // Requis, ID du produit sélectionné
    variant_id: '', // Requis, ID de la variante sélectionnée
    warehouse_id: null, // Optionnel, ID de l'entrepôt si sélectionné
    quantity: '', // Requis, quantité commandée initiale
    apro_m2_total: null, // Optionnel, surface totale en m²
    prix_unitaire_revient: '', // Requis, prix d'achat unitaire
    total_prix_achat: null, // Optionnel, prix d'achat total
    prix_vente: '', // Optionnel, prix de vente
    total_prix_vente: null, // Optionnel, prix de vente total
    fournisseur_id: null, // Optionnel, ID du fournisseur si sélectionné
    date_approvisionnement: new Date().toISOString().split('T')[0], // Requis, date d'approvisionnement
    date_reception: null, // Optionnel, date de réception
    status: '', // Optionnel, statut de l'approvisionnement (en cours, terminé, annulé, en attente, reçu)
    note: '', // Optionnel, note ou commentaire
    user_id: user?.id, // Optionnel, ID de l'utilisateur
    nb_m2_par_carton: null,
    nb_pieces_par_carton: '',
    total_pieces: '',
    nb_cartons_total: '',
    nb_m2_par_piece: '',
    no_m2: true,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [suppliers, setSuppliers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [products, setProducts] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState({ label: "Sélectionner un produit", value: null });
  const [selectedVariants, setSelectedVariants] = useState({ label: "Sélectionner une varainates", value: null });
  const [selectedSupplier, setSelectedSupplier] = useState({ label: "Sélectionner un fournisseur", value: null });
  const [selectedWarehouses, setSelectedWarehouses] = useState({ label: "Entrep\u00f4t Principal", value: 1 });
  const [selectedStatus, setSelectedStatus] = useState({ label: 'En cours', value: null });
  const [loading, setLoading] = useState(false);

  const fetchSuppliers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/fournisseurs`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setSuppliers(response.data.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des fournisseurs :', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/products`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setProducts(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des articles :', error);
    }
  };

  const fetchWarehouses = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/warehouses`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setWarehouses(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des warehouses :', error);
    }
  };

  const handleWheel = (e) => {
    // Empêche complètement le scroll
    e.target.blur();
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    // Bloque les flèches haut/bas
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchSuppliers();
    fetchWarehouses();
  }, []);

  const handleProductSelect = (option) => {
    setSelectedProduct(option);

    const lastVariant = option.allItems?.variants?.[option.allItems.variants.length - 1];

    // Vérifier si le produit sélectionné a une catégorie spécifique
    if (option?.allItems?.category_id === 17) {
      setFormData(prevState => ({
        ...prevState,
        no_m2: false,  // Utiliser apro_m2_total pour la quantité
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        no_m2: true,  // Utiliser total_pieces pour la quantité
      }));
    }

    if (lastVariant) {
      setSelectedVariants({
        label: lastVariant.name,
        value: lastVariant.id
      });
      setFormData(prevState => ({
        ...prevState,
        produit_id: option.value,
        variant_id: lastVariant.id
      }));
    } else {
      setSelectedVariants({ label: "Sélectionner une variante", value: null });
      setFormData(prevState => ({
        ...prevState,
        produit_id: option.value,
        variant_id: null
      }));
    }
  };

  const handleSuppliersSelect = (option) => {
    setSelectedSupplier(option);
    setFormData(prevState => ({
      ...prevState,
      fournisseur_id: option.value
    }));
  };

  const handleVariantsSelect = (option) => {
    setSelectedVariants(option);
    setFormData(prevState => ({
      ...prevState,
      variant_id: option.value
    }));
  };

  const handleStatusSelect = (option) => {
    setSelectedStatus(option);
    setFormData(prevState => ({
      ...prevState,
      status: option.value
    }));
  };

  const handleWarehousesSelect = (option) => {
    setSelectedWarehouses(option);
    setFormData(prevState => ({
      ...prevState,
      warehouse_id: option.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Récupérer le token depuis le localStorage
      const token = localStorage.getItem('token');
      if (!token) {
        // Si aucun token n'est trouvé, afficher une erreur
        throw new Error("Token manquant. Veuillez vous reconnecter.");
      }

      // Effectuer la requête POST pour soumettre les données
      const response = await axios.post(`${APP_API_URL}/approvisionnements`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      // Si la requête est réussie (status 201), afficher une alerte de succès
      if (response.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Succès',
          text: response.data.message || 'Approvisionnement créé avec succès !',
          timer: 2000,
          showConfirmButton: false,
        });

        // Fermer le modal après 1 seconde (enlever le commentaire pour activer la fermeture du modal)
        const closeModal = document.getElementById('return-to-supply-page');
        setTimeout(() => {
          if (closeModal) {
            closeModal.click(); // Décommenter si vous voulez réellement fermer le modal
          }
        }, 1000);
      }

    } catch (error) {
      // Gérer les erreurs, notamment celles liées à la validation
      if (error.response && error.response.status === 422) {
        // Erreurs de validation - afficher les erreurs spécifiques
        Swal.fire({
          icon: 'error',
          title: 'Erreur de validation',
          text: error.response.data.message || 'Veuillez corriger les erreurs dans le formulaire.',
          footer: '<strong>Vérifiez que tous les champs requis sont remplis correctement.</strong>',
        });
      } else if (error.message === "Token manquant. Veuillez vous reconnecter.") {
        // Erreur liée à l'absence de token
        Swal.fire({
          icon: 'error',
          title: 'Session expirée',
          text: error.message,
        });
      } else {
        // Erreurs génériques
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Erreur lors de l\'envoi du formulaire. Veuillez réessayer.',
        });
      }
    } finally {
      setLoading(false);  // Désactive le loader à la fin
    }
  };

  const handleRefresh = async () => {
    try {
      const token = localStorage.getItem('token');

      // Recharger les détails du produit sélectionné
      const response = await axios.get(`${APP_API_URL}/products/${selectedProduct.value}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Mettre à jour selectedProduct avec les nouvelles données
      const updatedProduct = response.data;
      setSelectedProduct(prev => ({
        ...prev,
        allItems: updatedProduct, // Remplace allItems par les nouvelles données du produit
      }));
    } catch (error) {
      console.error("Erreur lors du rafraîchissement des données du produit", error);
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    const closeModal = document.getElementById('return-to-supply-page');
    closeModal.click();
  };

  return (
    <MainLayout>
      <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center mb-16'>
        <div className="pagetitle">
          <h1>Nouvelle approvisionnement</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">Créer un nouvelle approvisionnement</li>
            </ol>
          </nav>
        </div>

        <div className='flex flex-col lg:flex-row gap-x-2 gap-y-4'>
          <div>
            <Link to="/approvisionnement" id="return-to-supply-page" className="inline-flex items-center rounded-md bg-darkgreen px-2.5 py-2 text-sm font-semibold text-white hover:bg-lightgreen transition-all duration-300">
              <i className="bi bi-arrow-left me-1" /> Retour aux approvisionnements
            </Link>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-body pt-4">
            <div className="accordion border-none" id="accordionPanelsStayOpenExample">
              <div className="accordion-item border-none p-0">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="inline-flex items-center text-lg font-semibold text-gray-900 py-3 accordion-button bg-white p-0 border-b"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="true"
                    aria-controls="flush-collapseOne"
                  >
                    <div>
                      <i className="bi bi-info-circle me-1 text-lightgreen" /> Informations sur l'approvisionnement
                    </div>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                >
                  <div className="accordion-body px-0">
                    <div className="row">
                      <div className="col-lg-4 col-sm-6 col-12 mb-3">
                        <label htmlFor="product" className="form-label">Choisir un produit</label>
                        <UIProductDropdown
                          options={products.map(item => ({
                            label: item.reference,
                            value: item.id,
                            allItems: item,
                            sku: item.sku,
                            name: item.name,
                            reference: item.reference,
                          }))}
                          selectedOption={selectedProduct}
                          onSelect={handleProductSelect}
                          enableSearch={true}
                        />
                      </div>

                      <div className="col-lg-4 col-sm-6 col-12 mb-3">
                        <label htmlFor="supplier" className="form-label">Choisir un fournisseur</label>
                        <UIDropdown options={suppliers.map(item => ({
                          label: item.nom,
                          value: item.id
                        }))}
                          selectedOption={selectedSupplier}
                          onSelect={handleSuppliersSelect}
                          enableSearch={true}
                        />
                      </div>

                      <div className="col-lg-4 col-sm-6 col-12 mb-3">
                        <label htmlFor="store" className="form-label">Choisir un magasin</label>
                        <UIDropdown options={warehouses.map(item => ({
                          label: item.name,
                          value: item.id
                        }))}
                          selectedOption={selectedWarehouses}
                          onSelect={handleWarehousesSelect}
                          enableSearch={true}
                        />
                      </div>
                    </div>

                    <div className="">
                      {selectedProduct.value !== null && (
                        <div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12 mb-3">
                              <div>
                                <div className="flex flex-row justify-between items-center mb-2">
                                  <label htmlFor="variant" className="form-label mb-0">Attribut de la variante</label>
                                  <button
                                    className='text-xs'
                                    title='Ajouter une variante'
                                    data-bs-toggle="modal"
                                    data-bs-target="#addVariantModal"
                                  >
                                    <i className={`bi bi-plus`}></i> Ajouter une variante
                                  </button>
                                  <Add productId={selectedProduct.value} reference={selectedProduct?.label} refreshData={handleRefresh} />
                                </div>
                                <UIProductDropdown
                                  options={selectedProduct.allItems?.variants.map(item => ({
                                    label: item.sku,
                                    value: item.id,
                                    sku: item.sku,
                                    name: item.name,
                                    reference: item.reference,
                                  }))}
                                  selectedOption={selectedVariants}
                                  onSelect={handleVariantsSelect}
                                  enableSearch={true}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-3 col-sm-6 col-12 mb-3">
                              <label htmlFor="nb_pieces_par_carton" className="form-label text-gray-950">Nombre de pièces par carton</label>
                              <input
                                type="number"
                                onWheel={handleWheel} // Désactive la molette
                                onKeyDown={handleKeyDown} // Désactive les flèches
                                className="form-control"
                                id="nb_pieces_par_carton"
                                name="nb_pieces_par_carton"
                                value={formData.nb_pieces_par_carton}
                                onChange={(e) => {
                                  const nbPiecesParCarton = parseFloat(e.target.value);
                                  const nbM2ParPiece = formData.nb_m2_par_carton / nbPiecesParCarton || 0;
                                  const totalPieces = nbPiecesParCarton * formData.nb_cartons_total;
                                  const m2Total = formData.nb_m2_par_carton * formData.nb_cartons_total;

                                  setFormData({
                                    ...formData,
                                    nb_pieces_par_carton: nbPiecesParCarton,
                                    nb_m2_par_piece: nbM2ParPiece,
                                    total_pieces: totalPieces,
                                    apro_m2_total: m2Total,
                                    total_prix_achat: m2Total * formData.prix_unitaire_revient,
                                  });
                                }}
                              />
                            </div>

                            <div className="col-lg-3 col-sm-6 col-12 mb-3">
                              <label htmlFor="nb_cartons_total" className="form-label text-gray-950">Nombre total de cartons</label>
                              <input
                                type="number"
                                onWheel={handleWheel} // Désactive la molette
                                onKeyDown={handleKeyDown} // Désactive les flèches
                                className="form-control"
                                id="nb_cartons_total"
                                name="nb_cartons_total"
                                value={formData.nb_cartons_total}
                                onChange={(e) => {
                                  const nbCartonsTotal = parseFloat(e.target.value);
                                  const totalPieces = formData.nb_pieces_par_carton * nbCartonsTotal;
                                  const m2Total = formData.nb_m2_par_carton * nbCartonsTotal;

                                  setFormData({
                                    ...formData,
                                    nb_cartons_total: nbCartonsTotal,
                                    total_pieces: totalPieces,
                                    apro_m2_total: m2Total,
                                    total_prix_achat: m2Total * formData.prix_unitaire_revient,
                                  });
                                }}
                              />
                            </div>

                            {/* Champs spécifiques pour "Carrelage" */}
                            {selectedProduct?.allItems?.category_id === 17 ? (
                              <>
                                <div className="col-lg-3 col-sm-6 col-12 mb-3">
                                  <label htmlFor="nb_m2_par_carton" className="form-label text-gray-950">Nombre de m² par carton</label>
                                  <input
                                    type="number"
                                    onWheel={handleWheel} // Désactive la molette
                                    onKeyDown={handleKeyDown} // Désactive les flèches
                                    className="form-control"
                                    id="nb_m2_par_carton"
                                    name="nb_m2_par_carton"
                                    value={formData.nb_m2_par_carton}
                                    onChange={(e) => {
                                      const nbM2ParCarton = e.target.value;
                                      const nbM2ParPiece = nbM2ParCarton / formData.nb_pieces_par_carton || 0;
                                      const m2Total = nbM2ParCarton * formData.nb_cartons_total;

                                      setFormData({
                                        ...formData,
                                        nb_m2_par_carton: nbM2ParCarton,
                                        nb_m2_par_piece: nbM2ParPiece,
                                        apro_m2_total: m2Total,
                                        total_prix_achat: m2Total * formData.prix_unitaire_revient,
                                      });
                                    }}
                                  />
                                </div>

                                <div className="col-lg-3 col-sm-6 col-12 mb-3">
                                  <label htmlFor="nb_m2_par_piece" className="form-label text-gray-950">Nombre de m² par pièce</label>
                                  <input
                                    type="number"
                                    onWheel={handleWheel} // Désactive la molette
                                    onKeyDown={handleKeyDown} // Désactive les flèches
                                    className="form-control bg-lightgreen/40"
                                    id="nb_m2_par_piece"
                                    name="nb_m2_par_piece"
                                    value={formData.nb_m2_par_piece}
                                    readOnly
                                  />
                                </div>

                                <div className="col-lg-3 col-sm-6 col-12 mb-3">
                                  <label htmlFor="apro_m2_total" className="form-label text-gray-950">Nombre de m² total</label>
                                  <input
                                    type="number"
                                    onWheel={handleWheel} // Désactive la molette
                                    onKeyDown={handleKeyDown} // Désactive les flèches
                                    className="form-control bg-lightgreen/40"
                                    id="apro_m2_total"
                                    name="apro_m2_total"
                                    value={formData.apro_m2_total}
                                    readOnly
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="col-lg-3 col-sm-6 col-12 mb-3">
                                <label htmlFor="total_pieces" className="form-label text-gray-950">Nombre total de pièces</label>
                                <input
                                  type="number"
                                  onWheel={handleWheel} // Désactive la molette
                                  onKeyDown={handleKeyDown} // Désactive les flèches
                                  className="form-control bg-lightgreen/40"
                                  id="total_pieces"
                                  name="total_pieces"
                                  value={formData.total_pieces}
                                  readOnly
                                />
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-lg-3 col-sm-6 col-12 mb-3">
                              <label htmlFor="prix_unitaire_revient" className="form-label">Prix unitaire de revient</label>
                              <input
                                type="number"
                                onWheel={handleWheel} // Désactive la molette
                                onKeyDown={handleKeyDown} // Désactive les flèches
                                min={1}
                                id="prix_unitaire_revient"
                                className="form-control"
                                value={formData.prix_unitaire_revient}
                                onChange={(e) => {
                                  const unitPrice = e.target.value;
                                  setFormData({
                                    ...formData,
                                    prix_unitaire_revient: unitPrice,
                                    // Recalcule automatiquement le prix total selon la catégorie
                                    total_prix_achat: (selectedProduct?.allItems?.category_id === 17
                                      ? formData.apro_m2_total // Calcul basé sur les m² pour la catégorie 17
                                      : formData.total_pieces // Calcul basé sur les cartons pour les autres catégories
                                    ) * unitPrice,
                                  });
                                }}
                              />
                            </div>

                            <div className="col-lg-3 col-sm-6 col-12 mb-3">
                              <label htmlFor="total_prix_achat" className="form-label">Prix total de revient</label>
                              <input
                                type="number"
                                onWheel={handleWheel} // Désactive la molette
                                onKeyDown={handleKeyDown} // Désactive les flèches
                                min={1}
                                id="total_prix_achat"
                                className="form-control bg-lightgreen/40"
                                value={formData.total_prix_achat}
                                readOnly // Rendre ce champ en lecture seule puisque c'est calculé automatiquement
                              />
                            </div>

                            <div className="col-lg-3 col-sm-6 col-12 mb-3">
                              <label htmlFor="prix_vente" className="form-label text-gray-950">Prix unitaire de vente</label>
                              <input
                                type="number"
                                onWheel={handleWheel} // Désactive la molette
                                onKeyDown={handleKeyDown} // Désactive les flèches
                                className="form-control"
                                id="prix_vente"
                                name="prix_vente"
                                value={formData.prix_vente}
                                onChange={(e) => setFormData({ ...formData, prix_vente: e.target.value })}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-3 col-sm-6 col-12 mb-3">
                              <label htmlFor="nb_m2_par_carton" className="form-label text-gray-950">Quantité actuelle</label>
                              <input
                                type="number"
                                onWheel={handleWheel} // Désactive la molette
                                onKeyDown={handleKeyDown} // Désactive les flèches
                                className="form-control bg-lightgreen/40"
                                value={(selectedProduct.allItems?.variants.filter(variant => variant.id === selectedVariants?.value)[0]?.quantity || 0)}
                                readOnly
                              />
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12 mb-3">
                              <label htmlFor="nb_m2_par_carton" className="form-label text-gray-950">Quantité finale</label>
                              <input
                                type="number"
                                onWheel={handleWheel} // Désactive la molette
                                onKeyDown={handleKeyDown} // Désactive les flèches
                                className="form-control bg-lightgreen/40"
                                value={selectedProduct?.allItems?.category_id === 17
                                  ? parseFloat(formData.apro_m2_total) + parseFloat(selectedProduct.allItems?.variants.filter(variant => parseFloat(variant.id) === parseFloat(selectedVariants?.value))[0]?.quantity || 0)
                                  : parseFloat(formData.total_pieces) + parseFloat(selectedProduct.allItems?.variants.filter(variant => parseFloat(variant.id) === parseFloat(selectedVariants?.value))[0]?.quantity || 0)}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-4 col-sm-6 col-12 mb-3">
                      <label htmlFor="supplier" className="form-label">État de l'approvisionnement</label>
                      <UIDropdown options={[
                        { label: 'En cours', value: 'en cours' },
                        { label: 'Terminé', value: 'terminé' }
                      ]}
                        selectedOption={selectedStatus}
                        onSelect={handleStatusSelect}
                        enableSearch={false}
                      />
                    </div>

                    <div className="col-lg-12">
                      <div className="input-blocks summer-description-box transfer mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea
                          className="form-control h-100"
                          rows={5}
                          value={formData.description}
                          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        />
                        <p className="mt-1 text-sm">Maximum 60 caractères</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-end mt-4 gap-x-4">
              {loading ? (
                <button
                  type="button"
                  disabled
                  className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-2 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Enregistrement...
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                >
                  Enregistrer l'approvisionnement
                </button>
              )}
              <button type="button" onClick={resetForm} className="inline-flex items-center rounded-md bg-gray-500 px-2.5 py-2 text-sm font-semibold text-white hover:bg-gray-800 transition-all duration-300">
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
